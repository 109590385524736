.skills-section {
  margin-top: -70px;
  margin-top: -460px;
  background-image: url(../img/bg_2.png),url(../img/bg_3.png),url(../img/bg_4.png),url(../img/bg_5.png);
  background-size: 120%,100%,100%,100%;
  background-repeat: no-repeat,no-repeat,no-repeat,no-repeat;
  background-position:100% -26px,0 47%,0px 90%,0 120%;
  padding-top: 380px;
 .img-left{
   padding-right: 40px !important;
 }
  .skills-item {
    .item-number {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      border: 3px solid #f4f8ff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #c6ccd7;
      font-weight: bold;
      font-size: 30px;
    }
    .item-title {
      margin-top: 10px;
      line-height: 50pt;
      font-size: 42px;
      color: #2e3e5a;
    }
    .item-description {
      margin-top: 10px;
      font-size: 18px;
      color: #969eac;
      line-height: 30pt;
    }
    .item-ul {
      margin-top: 24px;
      .ul-item {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

      }
    }
  }
  .skill-item-img {
  }
}