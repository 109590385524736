.portfolio-section {
  background-image: url("../img/portfolio_bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0px 17px;
  position: relative;
  padding-top: 185px;
  top: -100px;
  .portfolio-content {
    .portfolio-content-circle {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      border: 2px solid #f4f8ff;
      font-size: 24px;
      color: #c6ccd7;
    }
    flex-direction: column;
    display: flex;
    .content-title {
      font-size: 42px;
      color: #2e3e5a;
      margin-top: 16px;
      text-align: center;
    }
    .portfolio-content-item {
      border-radius: 3px;
      box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);
      .overlay-container {
        position: relative;
        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #3569d5;
          transition: 0.3s;
          opacity: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          span{
            text-align: center;
            font-size: 1.5rem;
            opacity: 0;
          }
          &:hover {
            opacity: 0.85;
            transition: 0.3s;
            span{
              opacity: 1;
            }
          }
        }
      }
    }
  }
}