@media (max-width: 375px) {
  #hamburger-btn {
    left: 0px !important;
  }

  .development-section .development-content .content-timeline .step .step-list .step-list-item .list-item_label {
    font-size: 20px !important;
  }
  .development-section .development-content .content-timeline .step .blue-box {
    padding: 40px 4px !important;
  }
  .skills-section .skills-item .item-title {
    font-size: 21px !important;
  }
  .skills-section .skills-item .item-ul .ul-item {
    font-size: 14px !important;
  }
}

@media (max-width: 414px) {
  #questions-modal .form-modal-content .form-descr,
  #questions-modal .form-modal-content .modal-content-subtitle {
    display: none;
  }

  #questions-modal .form-modal-content {
    padding: 16px !important;
  }
  #hamburger-btn {
    left: 0;
  }
  .header .header-preview .preview-audit .audit-btn {
    width: 100%;
  }
  .technology-section .technology-content .technology-content-list .item-col {
    display: flex;
    justify-content: center;
  }
  .technology-section {
    .technology-content {
      .technology-content-list {
        .frontend,
        .backend,
        .mobile {
          .item-col {
            flex: 0 0 50% !important;
            max-width: 50% !important;
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .development-section .development-content .content-timeline .step.step-circle-1:after {
    content: none;
  }
  .development-section .development-content .content-timeline .step.step-circle-2:after {
    content: none;
  }
  .development-section .development-content .content-timeline .step.step-circle-3:after {
    content: none;
  }
  .development-section .development-content .content-timeline .step.step-circle-4:after {
    content: none;
  }
  .development-section .development-content .content-timeline .step.step-circle-5:after {
    content: none;
  }
  header {
    padding-bottom: 180px !important;
  }
  #questions-modal .form-modal-content .input-row {
    margin-top: 26px;
  }
  #questions-modal .form-modal-content .modal-content-title {
    line-height: normal;
    font-size: 1.4rem;
    padding-right: 10px;
  }
  .header .header-preview .preview-audit .audit-title {
    font-size: 1.8rem;
    margin-top: 22px;
  }
  .close {
    left: auto !important;
    right: 25px !important;
  }
}

@media (max-width: 768px) {
  .footer-section .footer-container .footer-address,
  .footer-section .footer-container .footer-address:not(:last-child) {
    margin-bottom: 0px;
  }
  .technology-section .technology-content .technology-content-list .list .list-item .item-title {
    margin-top: 4px !important;
  }
  .development-section .development-content .content-timeline .step .step-list .step-list-item .list-item_label {
    font-size: 20px !important;
  }
  .blue-box {
    padding: 8px !important;
  }
  #questions-modal .form-modal-content {
    padding: 64px;
  }
  .footer-section .footer-container .copyright-row {
    display: none;
  }
  .footer-section .footer-container .footer-col-address {
    svg {
      display: none;
    }
  }
  .footer-section .footer-container .container-title {
    color: white;
  }
  .footer-section {
    padding-top: 480px !important;
    background-position: -131px 70px, -165px 0 !important;
  }
  .footer-section .footer-container .footer-col-map,
  .footer-section .footer-container .footer-col-address {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .portfolio-section .portfolio-content .portfolio-content-item .overlay-container {
    img {
      width: 100%;
    }
  }
  .portfolio-col-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .development-section .development-content .content-timeline .step .step-title {
    font-size: 26px;
  }
  .development-section .development-content .content-timeline .step .step-list .step-list-item span {
    font-size: 24px !important;
  }
  #hamburger-btn {
    left: 26px;
  }
  .development-section .development-content .content-timeline .step.reverse {
    flex-direction: column;
  }
  .blue-box {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
  .step-col-info {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .frontend,
  .backend,
  .mobile {
    .item-col {
      flex: 0 0 33% !important;
      max-width: 33% !important;
    }
  }
  .header .header-preview {
    .header-preview-title {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .header-preview-img {
      display: none;
    }
  }
  .skills-section .info-col {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }
  .skills-section .img-col {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
  }
  .header .header-navigation .action-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .header .header-preview .preview-notebook .notebook-img {
    display: none;
  }
  .skills-section .section-container .skill-item-img img {
    margin-left: 10px !important;
  }
  .skills-section .section-container .img-left {
    margin-left: -10px !important;
  }
  .skills-section .section-container .img-right {
    margin-left: -10px !important;
  }
}

@media (max-width: 992px) {
  .skills-section {
    background-image: url(../img/bg_4.png), url(../img/bg_5.png);
    background-size: 100%, 100%;
    background-repeat: no-repeat, no-repeat;
    background-position: 0px 90%, 0 120% !important;
  }
  .footer-section {
    background-image: url(../img/footer_bg.png);
    background-position: 100% 125px;
    background-repeat: no-repeat;
    background-size: auto;
  }
  .footer-section .footer-container .footer-address .address-info {
    font-size: 14px;
  }
  .skills-section .section-container .img-left {
    margin-left: -20%;
  }
  .skills-section .section-container .skill-item-img img {
    margin-left: 20%;
  }
  .skills-section .section-container .img-right {
    margin-left: -20%;
  }
  .technology-section .technology-content .content-title {
    margin-bottom: 16px;
  }
  .development-section .development-content .content-timeline .step .step-title {
    font-size: 24px;
  }
  .technology-section .technology-content .technology-content-list .list .list-item .item-description {
    font-size: 10px;
  }

  .development-section .development-content .content-timeline .step .step-list .step-list-item span {
    font-size: 16px;
  }
  .logo-col {
    max-width: 50%;
    flex: 0 0 50%;
    order: 1;
    display: flex;
  }
  .links-col {
    margin-top: 16px;
    flex: 0 0 100%;
    max-width: 100%;
    order: 3;
  }
  .action-col {
    max-width: 50%;
    flex: 0 0 50%;
    order: 2;
  }
  .footer-section {
    background-position: 362px 70px, -130px 0;
  }
  .technology-section {
    background-size: cover;
  }
  .list-labels {
    display: none;
  }
  .frontend,
  .backend,
  .mobile {
    border-top: 1px solid white;
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 16px;
    .item-col {
      flex: 0 0 25%;
      max-width: 25%;
      margin-top: 16px !important;
    }
  }
  .header .header-preview .preview-notebook {
    height: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  .technology-section .technology-content .technology-content-list .list .list-item {
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
  }
  .skills-section .skills-item .item-title {
    line-height: normal;
    font-size: 32px;
  }
  .skills-section .skills-item .item-description {
    font-size: 16px;
    line-height: 27pt;
  }
}

@media (max-width: 1024px) {
  #hamburger-btn {
    display: block;
  }
  .header .header-navigation .navigation-links {
    display: none;
  }
  .header .header-logo {
    display: none;
  }
}

@media (max-width: 1200px) {
  .technology-section .technology-content .technology-content-list .list .list-item .item-title {
    margin-top: 8px;
  }
  .technology-section .technology-content .technology-content-list .list .list-item {
    max-width: 140px !important;
    max-height: 140px !important;
    min-width: 140px !important;
    min-height: 140px !important;
  }
  .technology-section .technology-content .technology-content-list .list .list-item .item-title {
    font-size: 14px;
  }
  .technology-section .technology-content .technology-content-list .list .list-item .item-description {
    font-size: 8px;
  }
  .skills-section .skills-item .item-title {
    font-size: 28px;
    line-height: normal;
  }
  .header .header-preview .preview-notebook .notebook-img {
    max-width: 105%;
  }
  .technology-section {
    margin-top: 0px;
  }
  .footer-section {
    padding-top: 390px;
    background-position: 290px 70px, -165px 0;
  }
  .skills-section {
    padding-bottom: 100px;
    background-position: 86% 245px, 0 52%, 0 100%, 0 170%;
    margin-top: -355px;
    .section-container {
      .img-col {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .img-left {
        max-width: 100%;
        transform: none;
      }
      .img-right {
        max-width: 95%;
        transform: none;
      }
      .skill-item-img {
        img {
          max-width: 100%;
        }
      }
    }
  }
  header {
    padding-bottom: 140px;
  }
}

@media (max-width: 1366px) {
  .footer-section {
    background-position: 320px 125px, -41px -40px;
  }

  .header {
    padding-bottom: 60px;
  }
  .skills-section .section-container {
    padding-top: 32px;
  }
  .skills-section .section-container .img-col .img-left {
    height: auto;
    max-width: 100%;
    transform: none;
  }
  .skills-section .section-container .img-col .img-right {
    height: auto;
    max-width: 100%;
    transform: none;
    padding-right: 40px;
  }
}

@media (max-width: 1450px) {
  .footer-section {
    background-position: 357px 125px, 0px 0px;
  }
  .skills-section {
    margin-top: -380px;
    .skill-item-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

@media (max-width: 1660px) {
  .skills-section .section-container .img-col .img-right {
    transform: none;
    max-width: 100%;
    padding-right: 48px;
  }
  .skills-section .section-container .img-col .img-left {
    transform: none;
    max-width: 100%;
    padding-right: 48px;
  }
}

@media (max-width: 1700px) {
  .skills-section .section-container .img-col .skill-item-img {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 90%;
    }
  }
}
