.development-section {
  .development-background{
    background-image: url("../img/bg_3.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
  }
  .development-content {
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    .content-title {
      text-align: center;
      font-size: 42px;
      color: #2e3e5a;
    }
    .content-timeline {
      position: relative;

      .timeline {
        background-color: #dedfe3;
        height: 90%;
        position: absolute;
        left: calc(50% - 1px);
        margin-top: 50px;
        top: 64px;
        width: 1px;

      }
      .step {
        &.step-circle-1{
          &:after {
            content: '';
            position: absolute;
            z-index: 2;
            right: -22px;
            top: 16px;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-color: #e3deef;
          }
        }
        &.step-circle-2{
          &:after {
            content: '';
            position: absolute;
            z-index: 2;
            left: -23px;
            top: 60%;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-color: #c7bddf;
            box-shadow: 0 0 0 4px white;
          }
        }
        &.step-circle-3{
          &:after {
            content: '';
            position: absolute;
            z-index: 2;
            right: -26px;
            top: 5%;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            background-color: #98b3ea;
            box-shadow: 0 0 0 4px white;
          }
        }
        &.step-circle-4{
          &:after {
            content: '';
            position: absolute;
            z-index: 2;
            left: -26px;
            top: 65%;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            background-color: #658de0;
            box-shadow: 0 0 0 4px white;
          }
        }
        &.step-circle-5{
          &:after {
            content: '';
            position: absolute;
            z-index: 2;
            right: -26px;
            top: 10%;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            background-color: #3167d6;
            box-shadow: 0 0 0 4px white;
          }
        }
        position: relative;
        height: 90%;
        &.reverse {
          flex-direction: column-reverse;
        }
        margin-top: 76px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .step-title {
          color: #2e3e5a;
          font-size: 24pt;
          font-weight: bold;
        }
        .step-list {

          display: flex;
          flex-direction: column;
          margin-top: 8px;
          .step-list-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #969eac;
            span {
              font-size: 18pt;
              margin-left: 4px;
            }

          }
        }
      }
    }
  }
}