.technology-section {
  background-image: url("../img/technology_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 250px;
  .technology-content {
    display: flex;
    flex-direction: column;
    .content-title {
      text-align: center;
      color: white;
      font-size: 40px;
      padding: 0;
      margin: 0;
    }
    .cogs-circle {
      transform: translateY(-50%);
      border-radius: 40px;
      background-color: #2d5dcb;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 80px;
      height: 80px;
      color: white;
      font-size: 24pt;
      &.bottom{
        transform: translateY(50%);
      }
    }
    .technology-content-list {
      padding-bottom: 120px;
      .list-labels {
        padding-top: 72px;
        padding-bottom: 4px;
        border-bottom: 1px solid #688cdd;
        margin-bottom: 16px;
        .item-label {
          font-weight: 300;
          text-transform: uppercase;
          color: white;
          opacity: 0.5;
        }

      }
      .list {

        .list-item {
          overflow: hidden;
          transition: 0.3s;
          &:hover{
            transform: scale(1.1);
            transition: 0.3s;
          }
          min-width: 160px;
          max-width: 160px;
          max-height: 160px;
          min-height: 160px;
          display: flex;
          flex-direction: column;
          background-color: white;
          color: black;
          border-radius: 3px;
          padding: 30px 16px 16px 16px;

          justify-content: space-between;
          .item-title {
           margin-top: 16px;
           color: black;
          }
          .item-description {
            line-height: 12px;
           font-size: 11px;
            opacity: 0.5;
            white-space: nowrap;
          }
          &.es {
            background-color: #f8dc3d;
          }
          &.html5 {
            span {color: white;}
            background-color: #f16529;
          }
          &.redux {
            span {color: white;}
            background-color: #8447bf;
          }
          &.mssql {
            background-color: white;
          }
          &.python {
            span {color: white;}
            background-color: #1a466c;
          }
          &.css3 {
            span {color: white;}
            background-color: #409ad6;
          }
          &.antd {
            background-color: white;
          }
          &.d3 {
            span {color: white;}
            background-color: black;
          }
          &.celery {
            span {color: white;}
            background-color: #82c128;
          }
          &.django {
            background-color: white;
          }
          &.bootstrap {
            span {color: white;}
            background-color: #5d3b7f;
          }
          &.chartjs {
            background-color: white;
          }
          &.react {
            span {color: white;}
            background-color: #3bc4ec;
          }
          &.webpack {
            span {color: white;}
            background-color: black;
          }
          &.rabbitmq {
            span {color: white;}
            background-color: #ff6713;
          }
          &.flask {
            background-color: white;
            img{
              max-width: 60% !important;
            }
          }
          &.socket {
            background-color: white;
          }
          &.php {
            span {color: white;}
            background-color: #6082bb;
          }
          &.node {
            span {color: white;}
            background-color: #79c104;
          }
          &.laravel {
            span {color: white;}
            background-color: #f35045;
          }
          &.php {
            background-color: #1a466c;
          }
          &.mongodb {
            background-color: white;
          }
          &.android {
            img{
              max-width: 40% !important;
            }
            span {color: white;}
            background-color: #7fcd00;
          }
          &.redis {
            background-color: #f0f0f0;

          }
          &.ios {
            img{
              max-width: 40% !important;
            }
            background-color: white;

          }
          &.postgres {
            background-color: white;
          }
          &.oracle {
            span {color: white;}
            background-color: #ff0000;
          }
          &.mysql {
            background-color: white;
          }
        }

      }
    }
  }
}