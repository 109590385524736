.header {
  overflow-x: hidden;
  padding-bottom: 515px;
  background-image: url("../img/bg_1.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top left;
  .header-navigation {
    padding: 32px 0;
    .navigation-links {
      z-index: 3;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .link {
        cursor: pointer;
        outline: none;
        transition: 0.3s;
        font-size: 14px;
        color: white;
        text-transform: uppercase;
        &:hover {
          transition: 0.3s;
          color: #cccccc;
        }
      }
    }
    .navigation-btn {
      transition: 0.3s;
      &:hover{
        transition: 0.3s;
        transform: scale(1.05);
      }
      background-color: white;
      border-radius: 5px;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      color: #2e3e5a;
      font-size: 14px;
      border: none;
      padding: 16px 32px;
      cursor: pointer;
      box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
    }
  }
  .header-preview {
    .preview-audit {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      .audit-title {
        margin-top: 128px;
        color: white;
        font-family: HelveticaNeueCyr;
        font-weight: 900;
        font-size: 42px;
      }
      .audit-subtitle {
        margin-top: 50px;
        color: #cddbff;
        font-size: 24px;
      }
      .audit-btn {
        transition: 0.3s;
        &:hover{
          transition: 0.3s;
          transform: scale(1.05);
        }
        margin-top: 50px;
        background-color: #06d79c;
        border: none;
        border-radius: 5px;
        color: white;
        padding: 16px 32px;
        font-weight: 900;
        font-size: 16px;
        cursor: pointer;
        text-transform: uppercase;
        box-shadow: 0px 10px 25px 0px rgba(41, 43, 49, 0.15);
        &:focus {
          outline: 0;
        }
      }
    }
    .preview-notebook {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .notebook-img {
        max-width: 130%;
        transform: translate3d(-5%,10px,0);
      }
    }
  }
}
.img-left{
  transform: translateX(-25%);
}
.img-right{
  transform: translateX(-35%);
}