$hamburger-layer-color: white !default;
* {
  font-family: 'HelveticaNeueCyr';
  font-weight: normal;
  font-style: normal;
}

main {
  position: relative;
}

.body-overflow {
  overflow: hidden;
}

//.circle-in-ellipse {
//  width: 18px;
//  height: 18px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  border-radius: 9px;
//  border: 1px solid #1f40a7;
//  margin-right: 8px;
//  .circle {
//    background-color: #1f40a7;
//    width: 10px;
//    height: 10px;
//    border-radius: 5px;
//  }
//
//}

#hamburger-btn {

  cursor: pointer;
  z-index: 10;
  display: none;
  position: absolute;
  top: 32px;
  left: 36px;
  transition: 0.3s;
  &:focus {
    outline: none;
  }
  &.opened {
    transition: 0.3s;
    transform: translateX(182px);
  }

}

.circle-in-ellipse {
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #1f40a7;
  margin-right: 8px;
  background-color: #1f40a7;
  box-shadow: inset 0 0 0 3px white;
  &.white {
    border: 1px solid white;
    box-shadow: inset 0 0 0 3px #3167d6;
    background-color: white;
  }

}

.blue-box {
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #3167d6;
    transition: 0.3s;
    span {
      color: #d6cef7 !important;
    }
    h4 {
      color: white !important;
    }
    .circle-in-ellipse {
      border: 1px solid white;
      box-shadow: inset 0 0 0 3px #3167d6;
      background-color: white;
    }
  }

  background-color: white;
  margin-top: 36px;
  margin-left: 32px;
  width: 100%;
  h4 {
    color: #2e3e5a !important;
  }
  span {
    color: #969eac !important;
  }
  border-radius: 5px;
  padding: 42px 52px;

}

.step-circle {
  &:after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: red;
    z-index: 3;
  }
}

#slideout {
  .slideout-logo {
    margin-right: 58px;
  }
  background-image: url("../img/technology_bg.png");
  background-size: cover;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 5;
  background-color: white;
  width: 280px;
  height: 100%;
  transform: translateX(-100%);
  -webkit-transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -moz-transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -o-transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);

  -webkit-transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -moz-transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -o-transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  padding: 32px;
  .slideout-links {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    .slideout-link {
      cursor: pointer;
      color: white;
      font-size: 20px;
      text-transform: uppercase;
      padding: 8px;
      margin-top: 26px;
      font-weight: lighter;
    }
  }
}

.slideout-opened {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .15);
  transform: translateX(0) !important;
  -webkit-transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -moz-transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -o-transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);

  -webkit-transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -moz-transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  -o-transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

#questions-modal {
  border-radius: 3px;
  .modal-content {
    border: none;
    border-radius: 5px;
  }
  box-shadow: 5px 9px 54px 0px rgba(38, 39, 47, 0.15);
  .form-modal-content {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: relative;
    .close {
      position: absolute;
      top: 15px;
      left: 20px;
      font-size: 40px;
      color: white;
      &:focus {
        outline: none;
      }
    }
    padding: 112px 54px 112px 112px;
    background-image: url("../img/modal_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    .modal-content-title {

      line-height: 40px;
      font-weight: bold;
      font-size: 2rem;
      color: white;
    }
    .modal-content-subtitle {
      margin-top: 32px;
      color: #cddbff;
      font-size: 1.2rem;
    }
    .input-row {
      label {
        color: white;
      }
      input {
        color: #6f7d8c;
        font-size: 16px;
      }
      .form-action-btn {
        margin-top: 72px;
        border: none;
        border-radius: 3px;
        background-color: #06d79c;
        width: 100%;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: white;
        box-shadow: 5px 9px 25px 0px rgba(41, 43, 49, 0.15);
        font-weight: 900;
        font-size: 16px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          transition: 0.3s;
          transform: scale(1.1);
        }
      }
      margin-top: 64px;
      .modal-input {
        box-shadow: 0px 0px 10px 0px rgba(38, 39, 47, 0.1);
        margin-top: 6px;
        width: 100%;
        border: none;
        padding: 16px 32px;
        &::placeholder {
          font-size: 1.1rem;
          color: #6f7d8c;
        }
      }
    }
    .form-descr {
      margin-top: 32px;
      display: flex;
      color: #b6dcf7;
      svg {
        margin-right: 8px;
      }
      span {

      }
    }
  }
  .form-modal-logo {
    background-color: white;
    height: 100%;
    margin: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

.large-modal {
  max-width: 1112px;
}

.hamburger.is-active:hover, .hamburger:hover {
  opacity: 1 !important;
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  transition: 0.3s;
  background-color: white;
  opacity: 0;
  &.active {
    opacity: 1;
    transition: 0.3s;
    z-index: 15;
  }
}