.footer-section {
  padding-top: 550px;
  background-image: url(../img/footer_bg.png),url(../img/footer_bg_2.png);
  background-position: 100% 125px,125px -25px;
  background-repeat: no-repeat,no-repeat;
  background-size: auto,100%;
  margin-top: -350px;
  .footer-container {
    flex-direction: column;
    .footer-container-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      border-radius: 40px;
      border: 2px solid #f4f8ff;
      color: #c6ccd7;
      font-size: 30px;

    }
    .container-title {
      color: #2e3e5a;
      font-size: 42px;
      margin-top: 16px;
    }
    .map-container{
      height: 400px;
      width: 100%;
    }
    .copyright-row{
      margin-top: 54px;
      display: flex;
      flex-direction: column;
      .footer-divider{
        height: 1px;
        width: 100%;
        background-color: #344165;
        opacity: 0.25;
      }
      .copyright{
        color: white;
      }
    }
    .footer-address {
      &:not(:last-child){
        margin-bottom: 16px;
      }
      svg{
        color: #7992dc;
        font-size: 2rem;
      }
      .address-city {
        margin-top: 12px;
        font-size: 24px;
        color: white;
      }
      .address-info {
        font-size: 24px;
        color: #cddbff;
      }
      .divider-h {
        margin-top: 12px;
        height: 1px;
        width: 68px;
        background-color: white;
        opacity: 0.25;

      }
    }
  }
}